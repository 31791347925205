@import url("@goalteller/app-kit/styles/base.css");

:root {
  /* TODO: remove when ye-design is updated to v4 */
  --ye-shadow-input-focus: 0 0 0 1px var(--ye-color-input-focus-shadow);
  --audit-tool-banner-height: 35vh;
  --banner-content-wrapper: 280px;
  --bg-color-audit-tool: #fbf6f0;
  --bg-color-business-tool: #f4f6f8;
  --bg-color-kbc-tool: #f7f2f9;
  --bg-color-retirement-tool: #edf6f9;
  --card-color-audit-tool: #d0eaff;
  --card-color-business-tool: #e2eef8;
  --card-color-kbc-tool: #eed5d6;
  --card-color-kids-tool: #e1bf92;
  --card-color-retirement-tool: #5ea2be;
  --card-color-rent-vs-buy-tool: #98a68a;
  --card-dimension-mobile: 270px;
  --color-purple-c2-l5: #f8fffc;
  --color-primary: #228d6b;
  --color-primary-d2: var(--color-primary);
  --color-secondary-orange: #ec725f;
  --kids-goal-bg: var(--color-purple-c2-l5) 0% 0% no-repeat padding-box;
  --text-color-business-tool: #46607d;
  --text-color-kbc-tool: #b65c7b;
  --text-color-kids-tool: #c0a05a;
  --text-color-retirement-tool: #2f5160;
  --color-blue-c2-l5: #f3f6f2;
  --color-blue-c1-l2: oklch(88.11% 0.08 150.6deg);
  --color-blue-l2: var(--color-primary);
  --color-primary-c1-l3: var(--color-primary);
  --color-primary-c2-l4: var(--color-blue-c2-l5);
  --ye-color-input-focus-border: var(--color-primary);
  --ye-color-input-focus-shadow: var(--color-primary);
  --ye-color-primary: var(--color-primary);
}

input[type="radio"] {
  width: 16px;
  min-width: 8px;
  height: 16px;

  appearance: none;
  border: 2px solid var(--color-primary);
  border-radius: var(--half-size-dimension);
  outline: none;

  &:checked {
    background-color: var(--color-primary);
  }

  @media (--media-max-sm) {
    width: 8px;
    height: 8px;
  }
}

input[type="checkbox"] {
  width: var(--ye-font-size-normal);
  height: var(--ye-font-size-normal);

  appearance: none;
  border: 2px solid var(--color-primary);
  outline: none;

  &:checked {
    position: relative;
    background-color: var(--color-primary);

    &::after {
      content: "\2714";

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-size: var(--ye-font-size-xsmall);
      color: var(--color-canvas);
    }
  }
}

.toast-container {
  top: calc(3 * var(--ye-spacing-rem-xlarge));
}
