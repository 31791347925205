.root {
  position: fixed;
  z-index: var(--ye-z-topnav);
  top: 0;
  left: 0;

  width: var(--full-width);

  transition: all 0.5s ease-in-out;
  transition-property: width, left;
}

.maxwidth-blur {
  background-color: var(--color-canvas);
  transition:
    background-color 0.3s ease,
    opacity 0.3s ease;

  /* stylelint-disable-next-line csstools/media-use-custom-media */
  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

.contact-button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;

  font-size: var(--ye-spacing-rem-normal);
  color: var(--color-primary);

  @media (--media-max-sm) {
    font-size: var(--ye-font-size-xsmall);
  }
}

.banner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--ye-spacing-rem-xsmall);
}

.banner-links-container {
  display: flex;
  gap: var(--ye-spacing-rem-xhuge);
  align-items: center;
  justify-content: center;
}

.banner-callback-container {
  display: flex;
  gap: var(--ye-spacing-rem-xsmall);
  align-items: center;
  justify-content: center;

  @media (--media-max-md) {
    justify-content: flex-start;
  }
}

.contact-button-image {
  margin-right: var(--ye-spacing-rem-small);

  @media (--media-max-sm) {
    margin-right: var(--ye-spacing-rem-xsmall);
  }
}

.main-logo {
  width: var(--swiper-image-dimension);
  height: 100%;
}

.link-item {
  font-size: var(--ye-font-size-small);
  color: var(--color-primary-c2-d1);
}

.active-item {
  font-weight: 600;
  color: var(--color-primary);
}

@keyframes rotate-come-back {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-60deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.mobile-menu-icon {
  cursor: pointer;
  display: none;

  @media (--media-max-md) {
    display: block;
  }
}

.hamburger-icon {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: var(--ye-spacing-rem-xlarge);
  height: var(--ye-spacing-rem-normal);

  span {
    width: var(--full-width);
    height: 2px;
    margin: var(--ye-rem-unit) 0;
    background-color: var(--color-primary-c2-d1);
  }

  & .open {
    span {
      background-color: var(--color-primary);
    }
  }
}

.mobile-menu {
  position: absolute;
  z-index: var(--z-index-base);
  top: 100%;
  left: 0;

  display: none;
  flex-direction: column;

  width: var(--full-width);
  padding: var(--ye-spacing-rem-large);

  background-color: var(--color-canvas);

  @media (--media-max-md) {
    display: flex;
  }
}

.mobile-link-item {
  @mixin focusAndHover {
    font-weight: 700;
    color: var(--color-primary);
  }

  margin-bottom: var(--ye-spacing-rem-small);
  font-size: var(--ye-font-size-small);
  color: var(--color-primary-c2-d1);
}

/* to avoid undef error */
/* stylelint-disable-next-line no-descending-specificity */
.open {
  /* no styles */
}
